import Prism from "prismjs";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/autoloader/prism-autoloader";
import "prismjs/plugins/normalize-whitespace/prism-normalize-whitespace";

import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "./src/styles/main.css";

import "./static/cookie-consent";

Prism.plugins.autoloader.languages_path =
  "https://cdnjs.cloudflare.com/ajax/libs/prism/1.23.0/components/";

export const onRouteUpdate = () => {
  window.dispatchEvent(new Event("on-location-change"));
};
