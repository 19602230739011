window.hilla = window.hilla || {};
window.hilla.isGoogleAnalyticsAdded = false;
window.hilla.consentKey = "hilla-consent";
window.hilla.consentText =
  "We use cookies to improve user experience and analyze website traffic. Read our <a href='https://vaadin.com/privacy-policy' target='_blank'>privacy policy</a> to learn more.";

window.hilla.ga = (() => {
  var trackingId = "G-VLS2XEM4R5";

  return {
    addGoogleAnalyticsScript: () => {
      return new Promise((resolve) => {
        if (window.hilla.isGoogleAnalyticsAdded) {
          return resolve();
        }

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://www.googletagmanager.com/gtag/js?id=" + trackingId + "";

        script.onload = () => {
          window.hilla.isGoogleAnalyticsAdded = true;
          resolve();
        };

        document.getElementsByTagName("head")[0].appendChild(script);
      });
    },

    initializeGoogleAnalytics: () => {
      if (!window.hilla.isGoogleAnalyticsAdded) {
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      window.gtag("config", "" + trackingId + "");
    },

    triggerPageViewEvent: () => {
      var l = window.location;
      var pagePath = l.pathname + l.search + l.hash;

      window.gtag("set", "page_path", pagePath);
      window.gtag("event", "page_view");
    },

    trackGoogleAnalytics: () => {
      var isConfirmed = localStorage.getItem(window.hilla.consentKey);

      if (isConfirmed === "true" && typeof window.gtag === "function") {
        window.hilla.ga.triggerPageViewEvent();
      }
    },
  };
})();

window.hilla.consentModal = (() => {
  if (window.location.hostname !== "hilla.dev") {
    return;
  }

  var modal;
  var isConfirmed = localStorage.getItem(window.hilla.consentKey);

  if (isConfirmed === null) {
    showConsentModal();
  } else if (isConfirmed === "true") {
    startTacking();
  }

  function startTacking() {
    return new Promise((resolve) => {
      window.hilla.ga.addGoogleAnalyticsScript().then(() => {
        window.hilla.ga.initializeGoogleAnalytics();

        resolve();

        window.addEventListener("on-location-change", () => {
          // Compensating title update delay in Gatsby and other SPAs.
          // Otherwise analytics will show the title of the previous page
          setTimeout(() => {
            window.hilla.ga.trackGoogleAnalytics();
          }, 300);
        });
      });
    });
  }

  function handleConfirm() {
    localStorage.setItem(window.hilla.consentKey, true);

    startTacking().then(() => {
      window.hilla.ga.triggerPageViewEvent();
    });

    modal && modal.remove();
  }

  function showConsentModal() {
    injectModalStyles();

    modal = document.createElement("div");
    modal.classList.add("hilla-consent-modal");

    var modalContent = document.createElement("div");
    modalContent.classList.add("hilla-consent-modal--content");

    var text = document.createElement("p");
    text.innerHTML = window.hilla.consentText;

    var button = document.createElement("button");
    button.innerText = "OK";
    button.onclick = handleConfirm;

    modalContent.appendChild(text);
    modalContent.appendChild(button);

    modal.appendChild(modalContent);

    document.body.appendChild(modal);
  }

  function injectModalStyles() {
    var modalStyles = `
    .hilla-consent-modal {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      z-index: 99999;
    }
    .hilla-consent-modal--content {
      margin: 1rem;
      padding: 0.75rem 1rem;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 0.5rem;
    }
    .hilla-consent-modal p {
      margin: 0;
      font-size: 0.875rem;
      line-height: 1.6;
      color: #000;
    }
    .hilla-consent-modal button {
      margin-left: 1.25rem;
      padding: 0.25rem 1rem;
      font-size: 0.875rem;
      font-weight: bold;
      font-family: inherit;
      line-height: 1.6;
      background-color: #000;
      color: #fff;
      border: none;
      border-radius: 0.375rem;
      cursor: pointer;
    }
    .hilla-consent-modal a {
      color: #000;
      text-decoration: none;
      font-weight: 600;
      border-bottom: 1px solid #ccc;
    }
  `;

    var styles = document.createElement("style");
    styles.innerHTML = modalStyles;

    document.getElementsByTagName("head")[0].appendChild(styles);
  }
})();
